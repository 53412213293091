<template>
  <b-card
    no-body
    class="p-2 h-100 w-100 m-0"
  >
    <b-row>
      <b-col class="d-flex flex-column align-items-center">
        <b-avatar
          :src="participant?.participant?.avatar_image"
          :variant="`light-primary`"
          class="mb-2"
          size="100"
        />
        <h4 class="mb-2">
          {{
              participant?.participant
                  ? participant.participant.name
                  : participant.name
          }}
        </h4>
      </b-col>
    </b-row>

    <!-- lineup -->
    <b-row
      v-if="participant?.participant_type === TournamentParticipantType.team"
      class="mb-1"
    >
      <b-col class="text-center">
        <div v-if="!participant.lineup || participant.lineup.length === 0">
          <i>No lineup</i>
        </div>
        <b-badge
          v-for="lineup in participant?.lineup"
          :key="lineup.id"
          :disabled="
            lineup.name && lineup.participant === null
          "
          :to="
            lineup.participant
              ? {
                name: 'player-profile',
                params: { id: lineup.participant.id }
              }
              : null
          "
          class="mt-1 mr-1 lineup-badge text-truncate"
          variant="light-primary"
          style="max-width: 150px;"
        >
          {{
            lineup.player
              ? lineup.player.name
              : lineup.name
          }}
        </b-badge>
      </b-col>
    </b-row>

    <!-- edit lineup btn -->
    <b-row
      v-if="canEditLineup"
      class="mt-auto mb-1"
    >
      <b-col class="text-center">
        <b-button
          size="sm"
          variant="primary"
          @click="handleEditLineupClick"
        >
          Edit lineup
        </b-button>
      </b-col>
    </b-row>

    <!-- profile btn -->
    <b-row class="mt-auto">
      <b-col class="text-center">
        <b-button
          v-if="participant?.participant !== null"
          size="sm"
          variant="primary"
          @click="handleProfileClick"
        >
          Profile
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAvatar, BBadge, BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import { MemberRoleType } from '@/constants/MemberRoleType'
import TournamentStatusType from '@/constants/TournamentStatusType'

export default {
  components: {
    BBadge,
    BCard,
    BCol,
    BRow,
    BButton,
    BAvatar,
  },
  props: {
    participant: {
      type: Object,
      default: null,
    },
    tournament: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      MemberRoleType,
      TournamentParticipantType,
      TournamentStatusType,
    }
  },
  computed: {
    me() {
      return this.$store.getters['user/getMe']
    },
    canEditLineup() {
      if (!this.tournament?.status_id || this.tournament?.status_id !== TournamentStatusType.PUBLISHED) {
        return false
      }

      if (!this.me) {
        return false
      }

      return this.me?.teams.some(team => team.id === this.participant.participant_id && team?.members.some(member => member.user_id === this.me.id && (member.role.id === MemberRoleType.OWNER || member.role.id === MemberRoleType.TEAM_LEADER)))
    },
  },
  methods: {
    handleEditLineupClick() {
      this.$emit('editLineupClicked', this.participant)
    },
    handleProfileClick() {
      if (!this.participant?.participant_id) {
        return
      }

      this.$router.push({
        name:
            this.participant.participant_type === TournamentParticipantType.TEAM
              ? 'team-profile'
              : 'player-profile',
        params: { id: this.participant.participant_id },
      })
    },
  },
}
</script>

<style lang="scss">
.lineup-badge {
  padding: 6px 8px;
}
</style>
