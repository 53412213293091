<template>
  <div
    style="height: inherit"
  >
    <!-- View header -->
    <b-card
      no-body
      class="p-2"
    >
      <b-row class="align-items-center justify-content-between">
        <b-col
          sm="12"
          md="6"
        >
          <h5 class="m-0">
            Tournament participants
          </h5>
        </b-col>
        <b-col
          sm="12"
          md="6"
          class="d-flex justify-content-end"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="searchQuery"
              placeholder="Search participants ..."
              type="search"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                height="20px"
                icon="SearchIcon"
                width="20px"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- /View header -->

    <b-row
      v-if="isLoading"
      class="text-center p-2"
    >
      <b-spinner label="Loading..." />
    </b-row>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scroll-area"
    >
      <!-- Joint tournament card -->
      <b-row
        v-if="!isLoading"
        class="m-0"
      >
        <b-col
          v-if="showJoinTournamentCard && !myRegistrations"
          sm="12"
          md="6"
          lg="4"
          class="mb-1"
        >
          <b-card
            no-body
            class="p-2 h-100 w-100"
          >
            <div class="text-center">
              You did not join this tournament yet. Hurry up and join before all
              available spots are taken by others.
            </div>
            <div class="text-center mt-2">
              <b-link
                size="sm"
                variant="outline-primary"
                class="btn-tour-skip mr-1"
                @click="handleSaveRegistrationClick"
              >
                <feather-icon
                  icon="UserPlusIcon"
                  size="50"
                />
              </b-link>
            </div>
          </b-card>
        </b-col>

        <b-col
          v-if="showRegistrationCard"
          sm="12"
          md="6"
          lg="4"
          class="mb-1"
        >
          <b-card
            no-body
            class="p-2 h-100 w-100"
          >
            <div class="text-center">
              Your team registration is waiting for approval. You will see your team here once it is approved.
            </div>
            <div class="text-center mt-auto">
              <feather-icon
                icon="InfoIcon"
                size="50"
              />
            </div>

            <div
              v-if="canCancelRegistration(tournament)"
              class="text-center mt-auto"
            >
              <b-button
                size="sm"
                variant="danger"
                @click="handleWithdrawTournamentClick"
              >
                <b-spinner
                  v-if="isCancelingRegistration"
                  small
                />
                Withdraw from tournament
              </b-button>
            </div>

          </b-card>
        </b-col>

        <!-- No participants  -->
        <b-col
          v-if="!isLoading && (!participants || participants?.length === 0)"
          sm="12"
        >
          <div class="d-flex justify-content-center flex-column">
            <div class="font-italic m-2 d-flex justify-content-center">
              <feather-icon
                icon="InfoIcon"
                size="40"
              />
            </div>
            <div class="font-italic d-flex justify-content-center">
              No participants joined this tournament.
            </div>
          </div>
        </b-col>

        <!-- Participants list -->

        <b-col
          v-for="participant in participants"
          :key="participant.id"
          class="d-flex mb-1 pl-0"
          md="6"
          sm="12"
          lg="4"
        >
          <tournament-participant-card
            :key="participant.id"
            :participant="participant"
            :tournament="tournament"
            @editLineupClicked="handleEditLineupClick"
          />
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>

    <tournament-participant-lineup-modal
      :participant="selectedParticipant"
      :tournament="tournament"
    />
  </div>
</template>
<script>
import {
  BButton, BCard, BCol, BFormInput, BInputGroup, BInputGroupAppend, BLink, BRow, BSpinner,
} from 'bootstrap-vue'
import TournamentParticipantCard from '@/views/tournament/TournamentParticipantCard.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { Ability } from '@casl/ability'
import TournamentParticipantLineupModal from '@/views/tournament/TournamentParticipantLineupModal.vue'
import {
  canCancelRegistration,
  listMyTournamentParticipants,
  showJoinTournamentCard,
} from '@/views/tournament/tournament-utils'
import TournamentStatusType from '@/constants/TournamentStatusType'
import { breadcrumbManager } from '@core/mixins/bredcrumb-manager'
import { loggedUserIsTeamOwnerOrLeader } from '@/views/team/team-utils'
import { TournamentParticipantStatusType } from '@/constants/TournamentParticipantStatusType'

export default {
  components: {
    TournamentParticipantLineupModal,
    BLink,
    BButton,
    TournamentParticipantCard,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    VuePerfectScrollbar,
  },
  mixins: [
    breadcrumbManager,
  ],
  data() {
    return {
      TournamentParticipantStatusType,

      selectedParticipant: {},
      isCancelingRegistration: false,
      participants: [],
      searchQuery: '',
      perfectScrollbarSettings: {
        suppressScrollX: true,
      },
      ability: new Ability(),
    }
  },
  computed: {
    me() {
      return this.$store.getters['user/getMe']
    },
    showRegistrationCard() {
      if (Boolean(this.tournament?.enable_registration) === false) {
        return false
      }

      if ([TournamentStatusType.IN_PROGRESS, TournamentStatusType.FINISHED].includes(this.tournament?.status_id)) {
        return false
      }
      // console.log(this.myRegistrations && this.myRegistrations?.some(registration => registration.is_confirmed === null))
      return this.myRegistrations && this.myRegistrations?.some(registration => registration.is_confirmed === null)
    },
    showJoinTournamentCard() {
      return showJoinTournamentCard(this.tournament)
    },

    myRegistrations() {
      // show single withdrawal card
      if (!this.me) {
        return false
      }

      // find my team registration
      const myTournamentParticipants = listMyTournamentParticipants(this.tournament)
      if (!myTournamentParticipants) {
        return false
      }

      return this.tournament?.registrations.filter(registration => myTournamentParticipants.find(tournamentParticipant => tournamentParticipant.id === registration.participant.id))
    },
    tournament() {
      return this.$store.getters['tournament/getTournament']
    },
    isLoading() {
      return this.$store.getters['tournament/isLoading']
    },

  },
  watch: {
    searchQuery(searchValue) {
      if (!searchValue) {
        this.participants = this.tournament?.participants?.filter(participant => participant.status === TournamentParticipantStatusType.APPROVED)
      }

      this.participants = this.tournament?.participants
        .filter(participant => participant.status === TournamentParticipantStatusType.APPROVED)
        .filter(
          participant => {
            if (!participant.participant) {
              return participant.name
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            }
            return participant.participant.name
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          },
        )
    },
    tournament() {
      this.participants = this.tournament?.participants?.filter(participant => participant.status === TournamentParticipantStatusType.APPROVED) || []
      this.replaceBreadcrumb({
        find: 'tournamentName',
        replace: {
          text: this.tournament?.name,
          loading: false,
        },
      })
    },
  },
  async mounted() {
    this.participants = this.tournament?.participants?.filter(participant => participant.status === TournamentParticipantStatusType.APPROVED) || []

    await this.hydrateBreadcrumbs()
  },
  methods: {
    canCancelRegistration,
    loggedUserIsTeamOwnerOrLeader,
    handleEditLineupClick(participant) {
      this.selectedParticipant = JSON.parse(JSON.stringify(participant))
      this.$bvModal.show('tournament-participant-lineup-modal')
    },
    async handleSaveRegistrationClick() {
      if (this.me.teams?.length === 0 || !this.me.teams?.some(team => team.disciplines.some(discipline => discipline.discipline.id === this.tournament.discipline.id))) {
        this.$bvModal.show('tournament-registration-no-team-modal')
      } else {
        this.$bvModal.show('tournament-registration-modal')
      }
    },
    handleWithdrawTournamentClick() {
      this.$swal({
        title: 'Withdraw participation?',
        text: `Are you sure you want to withdraw from tournament? ${this.myRegistrations[0]?.participant.id}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Withdraw',
        cancelButtonText: 'Close',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            const participantId = this.myRegistrations[0]?.participant.id
            const { success } = await this.$api.tournament.withdrawFromTournament(this.tournament.id, participantId)

            if (success) {
              await this.$store.dispatch('tournament/fetchTournament', this.tournament.id)
            }
          }
        })
    },
    async hydrateBreadcrumbs() {
      const data = await this.$store.getters['tournament/getTournament']
      if (data != null) {
        this.replaceBreadcrumb({
          find: 'tournamentName',
          replace: {
            text: data?.name,
            loading: false,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
.scroll-area {
  height: calc(100% - 115px);
}
</style>
